
import React, { useEffect, useRef } from 'react'
import { useState } from 'react'

import 'react-slideshow-image/dist/styles.css';
import { Slide } from 'react-slideshow-image';


import firebase from 'firebase/compat/app';
import app from '../config/firebase.config'
import "firebase/compat/firestore";
import { firebaseConfig } from '../config/firebase.config'




try {
  firebase.initializeApp(firebaseConfig);
} catch (e) {
  console.log(e.message)
}

const db = firebase.firestore();



export function Servicedetail (){
const [slide,setSlide]=useState([])

useEffect(()=>{
  let pictures=[]
  db.collection("slidepic")
  .get()
  .then((onSnapshot) => {
      onSnapshot.forEach((doc) => {
          pictures.push(doc.data())
          console.log(doc.data())
      })
      setSlide(pictures)
  })
},[])
//
const Slideshow = () => {
    return (
      <div className="slidediv">
        <Slide easing="ease" className="reactslide">
          {slide.map((pic,index)=>(
            <img alt={pic.url} src={pic.url} className="each-slide"></img>
          ))}
        </Slide>
      </div>
    )
};
//
    return<div className="servicedetail">

    </div>
}