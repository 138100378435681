// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import firebase from 'firebase/compat/app';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyArIceCA2oA-dyRSzKg0bcyuUrUiZXAUPw",
  authDomain: "dusalonweb.firebaseapp.com",
  projectId: "dusalonweb",
  storageBucket: "dusalonweb.appspot.com",
  messagingSenderId: "383699929398",
  appId: "1:383699929398:web:7a61e15de914786cd94b97",
  measurementId: "G-K61K0TBQ5H"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default app
