import './App.css';
import { Link, Route, Routes, BrowserRouter as Router } from "react-router-dom";
import {Nav} from './components/nav'
import {Slidee} from './components/slides'
import {Footer} from './components/footer'
import {Servicedetail} from './components/servicedetail'
function App() {
  return (
    <Router>
    <div className="main">
      <div className="p1"><Nav/></div>
      <Routes>
        <Route path="/" element={<Slidee/>}></Route>
        <Route path="/:serviceId" element={<Servicedetail/>}></Route>
      </Routes>
      <div className="p3"><Footer/></div>
    </div>
    </Router>
  );
}

export default App;
