
import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import ButtonUnstyled, { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import { styled } from '@mui/system';
import { send } from 'emailjs-com';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  boxShadow: 24,
  p: 4,
};
const CustomButtonRoot = styled('button')`
  background-color: #795548;
  padding: 15px 20px;
  border-radius: 10px;
  color: #fff;
  font-weight: 600;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  transition: all 200ms ease;
  cursor: pointer;
  box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 0 rgba(0, 127, 255, 0);
  border: none;
  outline: none;
  &:hover {
    background-color: #3e2723;
    outline: none;
  }

  &.${buttonUnstyledClasses.active} {
    background-color: #3e2723;
  }

  &.${buttonUnstyledClasses.focusVisible} {
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
    box-shadow: 0 0 0 0 rgba(0, 127, 255, 0);
  }
`;
function CustomButton(props) {
  return <ButtonUnstyled {...props} component={CustomButtonRoot} />;
}
export function Nav (){
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [openn, setOpenn] = React.useState(false);
    const handleOpenn = () => setOpenn(true);
    const handleClosee = () => setOpenn(false);
    const [toSend, setToSend] = useState({
      from_name: '',
      number: '',
      time:'',
      message: '',
      note: '',
    });
    const handleChange = (e) => {
      setToSend({ ...toSend, [e.target.name]: e.target.value });
    };
    const onSubmit = (e) => {
      e.preventDefault();
      send(
        'service_ni4hljw',
        'template_1decqwe',
        toSend,
        'ZRAow7HmJ-bBzhnFV'
      )
        .then((response) => {
          console.log('SUCCESS!', response.status, response.text);
          console.log(toSend)
        })
        .catch((err) => {
          console.log('FAILED...', err);
        });
    };
    return<div className="head">
        
        <div className="item1">
            <a style={{marginLeft:"20px"}} href="https://dusalon.net/"><img className="item1" alt="logo.png" src={process.env.PUBLIC_URL + "/images/logo.png"} style={{width:"auto",maxWidth:"130px",height:"auto",maxHeight:"130px"}} /></a>            
        </div>
        <div className="item2">
            <Stack spacing={2} direction="row">
              <CustomButton onClick={handleOpen}>Bảng giá</CustomButton>
              <CustomButton onClick={handleOpenn}>Đặt hẹn</CustomButton>
            </Stack>
        </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="slidee">
          <img src={process.env.PUBLIC_URL + "/images/mat sau.jpg"} alt="menu" style={{ width: "100%", height: "auto" }} />
        </Box>
      </Modal>

      <Modal
        open={openn}
        onClose={handleClosee}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="slidee">
        <form className='sendmail' onSubmit={onSubmit} >
  <input
    type='text'
    name='from_name'
    placeholder='Tên'
    value={toSend.from_name}
    onChange={handleChange}
  />
  <input
    type='tel'
    name='number'
    placeholder='Số điện thoại'
    value={toSend.number}
    onChange={handleChange}
  />
  <input
    type='date'
    name='message'
    placeholder='Ngày'
    value={toSend.message}
    onChange={handleChange}
  />
    <input
    type='time'
    name='time'
    placeholder='Mình muốn làm gì ạ?'
    value={toSend.time}
    onChange={handleChange}
  />
  <input
    type='text'
    name='note'
    placeholder='Mình muốn làm gì ạ?'
    value={toSend.note}
    onChange={handleChange}
  />
  <br/><br/>
  <CustomButton onClick={onSubmit} type='submit'>Submit</CustomButton>
</form>
        </Box>
      </Modal>
    </div>
}