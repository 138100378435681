
import React, { useEffect, useRef } from 'react'
import { useState } from 'react'

import 'react-slideshow-image/dist/styles.css';
import { Slide } from 'react-slideshow-image';


import firebase from 'firebase/compat/app';
import app from '../config/firebase.config'
import "firebase/compat/firestore";
import { firebaseConfig } from '../config/firebase.config'




try {
  firebase.initializeApp(firebaseConfig);
} catch (e) {
  console.log(e.message)
}

const db = firebase.firestore();



export function Slidee (){
const [slide,setSlide]=useState([])

useEffect(()=>{
  let pictures=[]
  db.collection("slidepic")
  .get()
  .then((onSnapshot) => {
      onSnapshot.forEach((doc) => {
          pictures.push(doc.data())
          console.log(doc.data())
      })
      setSlide(pictures)
  })
},[])
//
const Slideshow = () => {
    return (
      <div className="slidediv">
        <Slide easing="ease" className="reactslide">
          {slide.map((pic,index)=>(
            <img alt={pic.url} src={pic.url} className="each-slide"></img>
          ))}
        </Slide>
      </div>
    )
};
//
    return<div className="slide">
        <div className='services'>
          <ul className='serviceul'>
            <li>
              <div className='list'>
                <img className='listpic' alt="cut"  src={process.env.PUBLIC_URL + "/images/cut.jpg"}/>
                <div class="overlay">
                <div class="button"><a className='atag' href="#"> CẮT </a></div>
                </div>
              </div>
            </li>
            <li>
            <div className='list'>
                <img className='listpic' alt="uon"  src={process.env.PUBLIC_URL + "/images/uon.jpg"}/>
                <div class="overlay">
                <div class="button"><a className='atag' href="#"> UỐN </a></div>
                </div>
              </div>
            </li>
            <li>
            <div className='list'>
                <img className='listpic' alt="duoi"  src={process.env.PUBLIC_URL + "/images/duoi.jpg"}/>
                <div class="overlay">
                <div class="button"><a className='atag' href="#"> DUỖI </a></div>
                </div>
              </div>
            </li>
            <li>
            <div className='list'>
                <img className='listpic' alt="nhuom"  src={process.env.PUBLIC_URL + "/images/nhuom.jpg"}/>
                <div class="overlay">
                <div class="button"><a className="atag" href="#"> NHUỘM </a></div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className='facebookfeed'>
        <iframe title='facebookfeed' src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FSalonDu%2F&tabs=timeline&width=340&height=400&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId&border_radius=10" width="340" height="400" style={{borderRadius:"10px",border:"none",overflow:"hidden",scrolling:"no",frameborder:"0",allowfullscreen:"true",allow:"autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"}}></iframe>
        {/* <Slideshow/> */}
        </div>
        
    </div>
}