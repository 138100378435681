import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { grey } from '@mui/material/colors';

export function Footer(){
    
    
    
    return <div className="footer">
        <div className="chia2">
        <div className="caigido">
            <div>
                <h5> <LocationOnIcon/> <a href='https://www.google.com/maps?ll=10.844532,106.672939&z=15&t=m&hl=vi&gl=US&mapclient=embed&cid=12151596415642548873' style={{textDecoration:"none"}}>612-614 Lê Đức Thọ, P.15, Q.Gò Vấp</a></h5>
            </div>
            <div>
                <h5> <PhoneIcon/> 090 138 4031</h5>
            </div>
        </div>
        <div className="bando">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3918.5653415568577!2d106.67074981483677!3d10.844537560898052!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752982ebd78bbd%3A0xa8a3248ee048ba89!2zU2Fsb24gRMWp!5e0!3m2!1svi!2s!4v1637660732571!5m2!1svi!2s" className="bando" width="400" height="auto" style={{border:"0"}} allowfullscreen="" loading="lazy"></iframe>
        </div>
        </div>
        <div className="mangxahoi">
            <div className="caigido">
            <a href="https://www.facebook.com/SalonDu" ><FacebookIcon fontSize="large" sx={{ color: grey[50] }} /></a>

            <a href="https://www.instagram.com/dusalon1988/"><InstagramIcon fontSize="large" sx={{ color: grey[50] }}/></a>

            </div>

        </div>
        <div className="chia2">
        <div className="caigido">
            <div>
                <h5> <LocationOnIcon/> 169 Nguyễn Văn Nghi, P.7, Q.Gò Vấp</h5>
            </div>
            <div>
                <h5> <PhoneIcon/> 035 897 4244</h5>
            </div>
        </div>
        <div className="bando">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d979.701067320613!2d106.68215806622895!3d10.826285788107564!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x317529c7eb22e7e3%3A0x3158f137fbc32d9a!2zc2Fsb24gRMWp!5e0!3m2!1svi!2s!4v1637659616688!5m2!1svi!2s" className="bando" width="400" height="auto" style={{border:"0"}} allowfullscreen="" loading="lazy"></iframe>
        </div>
        </div>

    </div>
}